.buttons {
    margin-top: 30px;
    text-align: left;
}

.button {
    background-color: grey;
    border-color: grey;
    color: white;
}
