.container {
    .list {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .description {
        padding-bottom: 20px;
    }
    .heading {
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: bold;
    }
    .inputNumber {
        width: 100%;
    }
    .extra {
        font-size: 10px;
    }
}
