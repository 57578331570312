.card {
    height: 100%;
    display: flex;
    flex-direction: column;

    div:nth-child(2) {
        flex: 1;
    }
    .date {
        font-size: 11px;
    }
    .state {
        display: block;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.88);
        cursor: default;

        &.SENT {
            color: green;
        }

        &.FAILED {
            color: orange;
        }
    }
}
