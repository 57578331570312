.container {
    margin-top: 10px;
}

.list {
    .date {
        margin-top: 30px;
        color: #94b4c6;
        font-weight: bold;
    }
    .table {
        margin-top: 24px;
        margin-bottom: 120px;
        .summary {
            font-weight: bold;
        }
    }
}
