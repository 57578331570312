.buttons {
    display: flex;
    justify-content: flex-end;
    font-size: smaller;
    border: none;
}

.table {
    flex-grow: 1;
}
