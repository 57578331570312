.container {
    .bank {
        h4 {
            margin-bottom: 8px;
        }
        p {
            margin: 0;
        }
    }
}
