.form {
    margin-bottom: 24px;

    .search {
        width: 100%;
    }
}

.icon {
    color: #94b4c6;
}
