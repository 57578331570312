.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: smaller;
    border: none;
    margin-bottom: 20px;
}
.icon {
    color: #94b4c6;
}
