.divider {
    padding-top: 15px;
    padding-bottom: 25px;
}
.table {
    margin-top: 24px;
    margin-bottom: 50px;
    .summary {
        font-weight: bold;
    }
}
