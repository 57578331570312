.buttons {
    display: flex;
    justify-content: flex-end;
    font-size: smaller;
    border: none;
}
.icons {
    display: flex;
    font-size: 18px;
    color: #94b4c6;
    font-weight: bold;
}
