/* desktop-first styles */

.layout {
    min-height: 100vh;

    header {
        background: #fff !important;
        height: 60px !important;
        padding-inline: 30px !important;

        .title {
            line-height: 5vw;
            .logo {
                margin-right: 0.5vw;
                width: 1.5vw;
            }
            .logoTitle {
                width: 6vw;
            }
        }
    }

    .content {
        flex: 1;
        background: #fff !important;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff !important;
        padding-left: 30px;
        padding-right: 30px;
        color: rgba(139, 141, 151, 0.5);
        .links {
            .link {
                color: rgba(139, 141, 151, 0.5);
            }
        }
        .copyright {
            display: flex;
            justify-content: center;
            margin-top: 8px;

            a {
                color: rgba(139, 141, 151, 0.5);
            }
        }
    }
}

/* mobile styles */
@media (max-width: 599px) {
    .layout {
        min-height: 100vh;

        header {
            background: #fff !important;
            height: 60px !important;
            padding-inline: 30px !important;

            .title {
                line-height: 20vw;
                .logo {
                    margin-right: 1.5vw;
                    width: 4vw;
                }
                .logoTitle {
                    width: 15vw;
                }
            }
        }

        .content {
            flex: 1;
            background: #fff !important;
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            padding-left: 5.5vw;
            padding-right: 5.5vw;
            font-size: 2.2vw;
            color: #fff;
            .links {
                .link {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
            .copyright {
                display: flex;
                justify-content: center;
                a {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
        }
    }
}

/* Styles for tablets */

@media screen and (min-width: 600px) and (max-width: 1024px) {
    .layout {
        min-height: 100vh;

        header {
            background: #fff !important;
            height: 60px !important;
            padding-inline: 30px !important;

            .title {
                line-height: 12vw;
                .logo {
                    margin-right: 0.6vw;
                    width: 2vw;
                }
                .logoTitle {
                    width: 9vw;
                }
            }
        }

        .content {
            flex: 1;
            background: #fff !important;
        }
        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #f5f5f5;
            padding-left: 5.5vw;
            padding-right: 5.5vw;
            font-size: 2vw;
            color: rgba(139, 141, 151, 0.5);
            .links {
                .link {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
            .copyright {
                display: flex;
                justify-content: center;
                a {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
        }
    }
}
