.container {
    margin-top: 10px;

    .table {
        margin-top: 24px;
        margin-bottom: 120px;
        .summary {
            font-weight: bold;
        }
    }
}
