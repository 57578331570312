.status {
    font-size: 24px;

    &.success {
        color: green;
    }

    &.error {
        color: red;
    }
}
