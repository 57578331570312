.form {
    margin-bottom: 24px;

    .filter {
        width: 100%;

        div:first-child {
            flex-grow: 1;
        }

        .tax {
            width: 200px;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        font-size: smaller;
        border: none;
    }
}

table {
    flex-grow: 1;
    .link {
        color: #ff7e73;
        font-weight: bold;
    }
}

.summary {
    font-weight: 600;
    background-color: #f3e3b3;
    .total {
        color: #ff7e73;
    }
}

.selectedSummary {
    font-weight: 600;
    background-color: #f0f3f7;
    .total {
        color: #ff7e73;
    }
}
