.date {
    width: 100%;
}

.document {
    width: 960px;
    height: 500px;
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 12px;
}
