.inputNumber {
    width: 100%;
}

.title {
    margin: 0;
}

.date {
    font-size: 12px;
}

.loader {
    display: flex;
    justify-content: center;
    margin-top: 96px;
    margin-bottom: 64px;

    span {
        font-size: 56px !important;

        i {
            width: 24px !important;
            height: 24px !important;
        }
    }
}
.table {
    flex-grow: 1;
    padding-top: 30px;
    padding-bottom: 30px;
}
