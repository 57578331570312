.buttons {
    margin-top: 30px;
    text-align: left;
}
.table {
    thead {
        th {
            background-color: transparent !important;
            font-weight: lighter !important;
        }
    }
    margin-top: 20px;
    border-color: transparent;
}
