/* desktop-first styles */

.container {
    min-height: 100vh;
    .top {
        width: 100%;
        min-height: 100%;
        top: 0;
        left: 0;
        background-image: url('./../../../src/resources/images/home-background.svg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .header {
            background: transparent;
            height: 4vw !important;
            padding-inline: 3.5vh !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .title {
                line-height: 5vw;
                display: flex;
                justify-content: flex-start;
                align-self: flex-start;
                padding-top: 1vw;
                .logo {
                    margin-right: 0.6vw;
                    width: 1.5vw;
                }
                .logoTitle {
                    width: 6vw;
                }
            }
            .options {
                font-size: 1vw;
                font-weight: 580;
                line-height: 2.5vw;
                color: #367496;
                align-self: flex-end;
            }
        }

        .titleSection {
            display: flex;
            flex-direction: row;
            .text {
                flex-basis: 30vw;
                flex-grow: 2;
                color: black;
                font-size: 2vw;
                font-weight: 500;
                padding-bottom: 7vw;
                padding-left: 5vw;
                .icon {
                    color: #edb616;
                    padding-right: 1vw;
                }
                h1 {
                    color: #367496;
                    padding-top: 15vh;
                    font-size: 3vw;
                }
                p {
                    line-height: 2vw;
                }
            }
        }
        .button {
            width: 18vw;
            height: 7vh;
            margin-top: 2.5vw;
            font-size: 1.5vw;
            font-weight: bold;
            text-align: center;
            margin-left: auto;
            border-radius: 1vw;
            background-color: #edb616;
        }
        .laptopImage {
            flex-basis: 35vw;
            flex-grow: 2;
            padding-left: 7vw;
            padding-top: 25vh;

            .image {
                justify-content: center;
                align-items: center;
                padding-right: 0vw;
                width: 100%;
            }
        }
    }

    .benefitsHeader {
        h2 {
            color: black;
            font-size: 3vw;
            text-align: left;
            padding-left: 5vw;
        }
    }

    .benefits {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        padding-left: 5vw;
        padding-right: 3vw;

        .imageContainer {
            flex-basis: 100vw;
            flex-grow: 2;
            padding-bottom: 8vw;
            padding-top: 3vw;
            .image {
                width: 100%;
            }
        }

        .text {
            flex-basis: 40vw;
            flex-grow: 2;
            text-align: left;
            color: #94b4c6;
            font-size: 1.1vw;
            vertical-align: middle;

            h2 {
                line-height: 2.5vw;
            }
            .dot {
                height: 4.1vw;
                width: 4.1vw;
                background-color: #94b4c6;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                float: left;
                line-height: 4vw;
                margin-right: 1.5vw;
            }
            p {
                margin-right: 1.5vw;
                margin-left: 6vw;
                margin-top: 0.5vh;
                margin-bottom: 1.2vh;
                color: black;
            }
        }
    }
    .description {
        background-color: #94b4c6;
        padding-left: 3vw;
        padding-right: 3vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
        font-weight: 550;
        p {
            color: #fff;
            text-align: center;
            font-size: 1.7vw;
        }
    }
    .functionalitiesHeader {
        h2 {
            color: #367496;
            font-size: 3vw;
            text-align: left;
            padding-left: 5vw;
            padding-top: 3vh;
        }
    }
    .functionalities {
        display: flex;
        flex-direction: row;
        padding-left: 5vw;
        padding-right: 2vw;

        .text {
            flex-basis: 100vw;
            flex-grow: 2;
            text-align: left;
            color: #000000be;
            font-size: 2vw;
            font-weight: 570;
            line-height: 2vw;
        }
        .icon {
            color: #edb616;
            padding-right: 1vw;
        }

        .imageContainer {
            flex-basis: 100vw;
            flex-grow: 2;
            padding-bottom: 6.5vh;
            padding-left: 3vw;

            .image {
                width: 85%;
                padding-inline: auto;
                margin-top: -4.5vw;
            }
        }
    }
    .contact {
        padding-left: 3.5vw;
        padding-right: 3.5vw;
        padding-top: 6vw;
        padding-bottom: 6vw;
        text-align: center;
        color: #367496;
        background-color: #f5f5f5;
        h2 {
            color: black;
            font-size: 3vw;
        }
        p {
            font-size: 2vw;
        }
        .button {
            width: 16vw;
            height: 5vw;
            margin-top: 2vw;
            font-size: 1.5vw;
            text-align: center;
            border-radius: 1vw;
            font-weight: 500;
            margin-left: auto;
            background-color: #edb616;
            .icon {
                padding-right: 0.3vw;
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f5;
        padding-left: 3vw;
        padding-right: 2.5vw;
        font-size: 1vw;
        color: rgba(139, 141, 151, 0.5);
        .links {
            .link {
                color: rgba(139, 141, 151, 0.5);
            }
        }
        .copyright {
            display: flex;
            justify-content: center;
            a {
                color: rgba(139, 141, 151, 0.5);
            }
        }
    }
}

/* mobile styles */
@media (max-width: 599px) {
    .container {
        .top {
            width: 100%;
            min-height: 100%;
            top: 0;
            left: 0;
            background-image: url('./../../../src/resources/images/home-background.svg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .header {
                padding-top: 5vw;
                .title {
                    line-height: 50vw;
                    display: flex;
                    justify-content: flex-start;
                    // align-self: flex-start;
                    padding-right: 1vw;
                    padding-left: 1vw;
                    .logo {
                        margin-right: 0.6vw;
                        width: 2.5vw;
                    }
                    .logoTitle {
                        width: 5.5vw;
                    }
                }
                .options {
                    font-size: 2vw;
                    font-weight: 580;
                    line-height: 5vw;
                    color: #367496;
                    align-self: flex-end;
                    padding-right: 3vw;
                    padding-top: 16vw;
                }
            }

            .titleSection {
                display: flex;
                flex-direction: column;
                .text {
                    flex-basis: auto;
                    color: black;
                    font-size: 4vw;
                    font-weight: 500;
                    text-align: center;
                    padding-bottom: 0vw;
                    padding-left: 0vw;
                    .icon {
                        color: #edb616;
                        padding-right: 1vw;
                    }
                    h1 {
                        color: #367496;
                        padding-top: 16vw;
                        font-size: 7vw;
                    }
                    p {
                        line-height: 3vw;
                    }
                }

                .button {
                    width: 30vw;
                    height: 12vw;
                    margin-top: 4.5vw;
                    font-size: 4vw;
                    font-weight: bold;
                    text-align: center;
                    margin-left: auto;
                    background-color: #edb616;
                    margin-bottom: 10vw;
                }
                .laptopImage {
                    flex-basis: 0vw;
                    flex-grow: 0;
                    padding-left: 0;
                    padding-top: 4vw;
                    padding-bottom: 3vw;
                    background-color: rgba(178, 235, 242, 0.4);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-right: 0vw;
                    height: 50vw;

                    .image {
                        justify-content: center;
                        align-items: center;
                        padding-right: 0vw;
                        width: 100%;
                    }
                }
            }
        }
        .benefitsHeader {
            h2 {
                color: black;
                font-size: 7vw;
                text-align: center;
                padding-top: 4vw;
                padding-left: 0vw;
                padding-bottom: 0vw;
            }
        }
        .benefits {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding-left: 0vw;

            .heading {
                font-size: 6vw;
                text-align: center;
            }

            .imageContainer {
                width: 100%;
                padding-bottom: 0vw;
                text-align: center;
                line-height: 1;
                flex-basis: 65vw;
                flex-grow: 2;

                .image {
                    width: 90%;
                    display: block;
                    margin: auto;
                }
            }
            .text {
                flex-basis: 100vw;
                text-align: center;
                color: #94b4c6;
                font-size: 4vw;
                padding-bottom: 1vw;

                h3 {
                    padding-right: 4.5vw;
                    padding-left: 4.5vw;
                }
                p {
                    margin-right: 0vw;
                    margin-left: 0vw;
                    padding-right: 3.5vw;
                    padding-left: 3.5vw;
                    margin-top: 0.5vw;
                    margin-bottom: 4.5vw;
                    color: black;
                }
            }
        }
        .description {
            background-color: #6190ab;
            padding-left: 4.5vw;
            padding-right: 4.5vw;
            padding-top: 4vw;
            padding-bottom: 4vw;
            p {
                color: #fff;
                text-align: center;
                font-size: 4vw;
                font-weight: 550;
            }
        }
        .functionalitiesHeader {
            h2 {
                color: #367496;
                font-size: 6vw;
                text-align: center;
                padding-left: 5vw;
                padding-top: 5vw;
            }
        }
        .functionalities {
            display: flex;
            flex-direction: column;
            padding-right: 2vw;
            padding-bottom: 0vh;

            .text {
                flex-basis: 50vw;
                flex-grow: 2;
                text-align: left;
                padding-left: 12vw;
                color: #000000be;
                font-size: 4vw;
                font-weight: 650;
                line-height: 5vw;
                padding-bottom: 3vw;
            }
            .icon {
                color: #edb616;
                padding-right: 2vw;
            }

            .imageContainer {
                flex-basis: 50vw;
                flex-grow: 2;
                padding-bottom: 2vw;
                padding-left: 0vw;
                margin-top: 1.5vw;

                .image {
                    width: 70%;
                    padding-inline: auto;
                    display: block;
                    margin: auto;
                    padding-bottom: 5vw;
                    margin-top: 0;
                }
            }
        }
        .contact {
            padding-left: 3.5vw;
            padding-right: 3.5vw;
            padding-top: 6vw;
            padding-bottom: 6vw;
            text-align: center;
            color: #367496;
            background-color: #f5f5f5;
            h2 {
                color: black;
                font-size: 6vw;
            }
            p {
                font-size: 4vw;
                font-weight: 580;
            }
            .button {
                width: 40vw;
                height: 10vw;
                margin-top: 5vw;
                background-color: #edb616;
                font-size: 4.5vw;
                font-weight: 550;
                text-align: center;
                margin-left: auto;
            }
        }
        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #f5f5f5;
            padding-left: 5.5vw;
            padding-right: 5.5vw;
            font-size: 2.5vw;
            color: rgba(139, 141, 151, 0.5);
            .links {
                .link {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
            .copyright {
                display: flex;
                justify-content: center;
                a {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
        }
    }
}

/* Styles for tablets */

@media screen and (min-width: 600px) and (max-width: 1024px) {
    .container {
        min-height: 100vh;
        .top {
            width: 100%;
            min-height: 100%;
            top: 0;
            left: 0;
            background-image: url('./../../../src/resources/images/home-background.svg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .header {
                background: transparent;
                height: 4vw !important;
                padding-inline: 3.5vh !important;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 4vw;
                .title {
                    line-height: 50vw;
                    display: flex;
                    justify-content: flex-start;
                    align-self: flex-start;
                    padding-top: 0vw;
                    .logo {
                        margin-right: 0.6vw;
                        width: 2vw;
                    }
                    .logoTitle {
                        width: 9vw;
                    }
                }
                .options {
                    font-size: 1.4vw;
                    font-weight: 580;
                    line-height: 2.5vw;
                    color: #367496;
                    align-self: flex-end;
                }
            }

            .titleSection {
                display: flex;
                flex-direction: row;
                .text {
                    flex-basis: 30vw;
                    flex-grow: 2;
                    color: black;
                    font-size: 2vw;
                    font-weight: 500;
                    padding-bottom: 7vw;
                    padding-left: 5vw;
                    .icon {
                        color: #edb616;
                        padding-right: 1vw;
                    }
                    h1 {
                        color: #367496;
                        padding-top: 12vw;
                        font-size: 3vw;
                    }
                    p {
                        line-height: 2vw;
                    }
                }
            }
            .button {
                width: 18vw;
                height: 6.5vw;
                margin-top: 4vw;
                font-size: 2.5vw;
                font-weight: bold;
                text-align: center;
                margin-left: auto;
                background-color: #edb616;
            }
            .laptopImage {
                flex-basis: 30vw;
                flex-grow: 2;
                padding-left: 0vw;
                padding-top: 20vw;

                .image {
                    justify-content: center;
                    align-items: center;
                    padding-right: 0vw;

                    width: 70%;
                }
            }
        }

        .benefitsHeader {
            h2 {
                color: black;
                font-size: 3vw;
                text-align: left;
                padding-left: 5vw;
            }
        }

        .benefits {
            background-color: #fff;
            display: flex;
            flex-direction: row;
            padding-left: 5vw;
            padding-right: 2vw;

            .imageContainer {
                flex-basis: 100vw;
                flex-grow: 2;
                padding-bottom: 8vw;
                padding-top: 15vw;
                .image {
                    width: 100%;
                }
            }

            .text {
                flex-basis: 80vw;
                flex-grow: 2;
                text-align: left;
                color: #94b4c6;
                font-size: 1.5vw;
                margin-right: 2vw;
                margin-left: 2vw;

                h3 {
                    line-height: 2.5vw;
                    padding-top: 0.3vw;
                    margin-right: 2vw;
                    margin-left: 2vw;
                    padding-left: 4vw;
                }
                .dot {
                    height: 4.1vw;
                    width: 4.1vw;
                    background-color: #94b4c6;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    float: left;
                    line-height: 4vw;
                    margin-right: 1.5vw;
                }
                p {
                    margin-top: 0.5vw;
                    margin-right: 0.5vw;
                    margin-bottom: 1.9vw;
                    font-size: 1.5vw;
                    color: black;
                }
            }
        }

        .description {
            background-color: #94b4c6;
            padding-left: 2.2vw;
            padding-right: 2.2vw;
            padding-top: 6vw;
            padding-bottom: 6vw;
            font-weight: 550;
            p {
                color: #fff;
                text-align: center;
                font-size: 1.7vw;
            }
        }
        .functionalitiesHeader {
            h2 {
                color: #367496;
                font-size: 3vw;
                text-align: left;
                padding-left: 5vw;
                padding-top: 4vw;
            }
        }
        .functionalities {
            display: flex;
            flex-direction: row;
            padding-left: 5vw;
            padding-right: 2vw;

            .text {
                flex-basis: 100vw;
                flex-grow: 2;
                text-align: left;
                color: #000000be;
                font-size: 2vw;
                font-weight: 570;
                line-height: 2vw;
            }
            .icon {
                color: #edb616;
                padding-right: 1vw;
            }

            .imageContainer {
                flex-basis: 100vw;
                flex-grow: 2;
                padding-bottom: 6.5vw;

                .image {
                    width: 80%;
                    margin-top: -5vw;
                    padding-inline: auto;
                }
            }
        }
        .contact {
            padding-left: 4vw;
            padding-right: 4vw;
            padding-top: 5vw;
            padding-bottom: 5vw;
            text-align: center;
            color: #367496;
            background-color: #f5f5f5;
            h2 {
                color: black;
                font-size: 3.5vw;
            }
            p {
                font-size: 2.5vw;
            }
            .button {
                width: 18vw;
                height: 7vw;
                margin-top: 4vw;
                font-size: 2.2vw;
                font-weight: bold;
                text-align: center;
                margin-left: auto;
                background-color: #edb616;
            }
        }
        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #f5f5f5;
            padding-left: 5.5vw;
            padding-right: 5.5vw;
            font-size: 2vw;
            color: rgba(139, 141, 151, 0.5);
            .links {
                .link {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
            .copyright {
                display: flex;
                justify-content: center;
                a {
                    color: rgba(139, 141, 151, 0.8);
                }
            }
        }
    }
}
