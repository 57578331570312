.files {
    padding-left: 28px;
    margin-top: 28px;

    h4 {
        font-weight: 400;
        margin-bottom: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .icon {
        font-size: 24px;
    }
}

.link {
    color: #0555e6;
    text-decoration: underline;
    margin-left: 6px;
}
