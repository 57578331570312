.inputNumber {
    width: 100%;
}

.menu {
    margin-bottom: 36px;

    .group {
        margin-top: 10px;
    }

    .item {
        height: 100%;
        border: 1px solid #cfd3d4;
        background-color: rgba(245, 245, 245, 0.4);

        .image {
            width: 35%;
        }
        .icon {
            padding-top: 10px;
            font-size: 38px;
        }
        .title {
            padding-left: 8px;
            font-size: 15px;
        }
        .description {
            font-size: 10px;
        }
        .links {
            text-align: left;
            padding-left: 10px;
        }
    }

    .inactive {
        pointer-events: none;
        opacity: 0.6;
        //background-color: #fefefe;
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;
    font-size: smaller;
    border: none;
    padding-top: 10px;
}
