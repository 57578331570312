.button {
    display: flex;
    position: absolute;
    top: 30%;
    align-items: center;
    font-size: smaller;
    border: none;
}
.date {
    width: 100%;
}
