/* desktop-first styles */
.pageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: center;
        gap: 2vw;
        .textContainer {
            flex-basis: 30vw;
            flex-grow: 1;
            padding-left: 6vw;
            font-size: 2.2vw;

            h2 {
                color: #367496;
            }
            .email {
                display: flex;
                align-items: center;
                gap: 0.5vw;
                .icon {
                    padding-right: 15px;
                    color: #94b4c6;
                }
            }
        }
        .imageContainer {
            flex-basis: 45vw;
            flex-grow: 1;
            .image {
                padding-left: 10vw;
                width: 90%;
            }
        }
    }
}

/* mobile styles */
@media (max-width: 599px) {
    .pageWrapper {
        display: block;
        justify-content: unset;
        align-items: unset;
        height: unset;
        .container {
            display: block;
            flex-direction: unset;
            align-items: unset;
            justify-content: unset;
            gap: unset;
            .textContainer {
                flex-basis: unset;
                flex-grow: unset;
                padding-left: 8vw;
                padding-right: 8vw;
                font-size: 4vw;
                padding-top: 7vw;

                h2 {
                    color: #367496;
                }
                .email {
                    display: flex;
                    align-items: center;
                    gap: 0.5vw;
                    .icon {
                        padding-right: 15px;
                        color: #94b4c6;
                    }
                }
            }
            .imageContainer {
                flex-basis: unset;
                flex-grow: unset;
                text-align: center;
                .image {
                    padding-left: 0vw;
                    width: 60%;
                }
            }
        }
    }
}

/* Styles for tablets */

@media screen and (min-width: 600px) and (max-width: 1024px) {
    .pageWrapper {
        display: block;
        justify-content: unset;
        align-items: unset;
        height: unset;
        .container {
            display: block;
            flex-direction: unset;
            align-items: unset;
            justify-content: unset;
            gap: unset;
            .textContainer {
                flex-basis: unset;
                flex-grow: unset;
                padding-left: 8vw;
                padding-right: 8vw;
                font-size: 4vw;
                padding-top: 7vw;

                h2 {
                    color: #367496;
                }
                .email {
                    display: flex;
                    align-items: center;
                    gap: 0.5vw;
                    .icon {
                        padding-right: 15px;
                        color: #94b4c6;
                    }
                }
            }
            .imageContainer {
                flex-basis: unset;
                flex-grow: unset;
                text-align: center;
                .image {
                    padding-left: 0vw;
                    width: 60%;
                }
            }
        }
    }
}
