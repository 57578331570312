.form {
    margin-bottom: 24px;
    width: 100%;

    .filter {
        width: 100%;

        div:first-child {
            flex-grow: 1;
        }

        .phase {
            width: 350px;
        }
    }
    .checkbox {
        font-size: 15px;
    }
}
