.ant-menu-item svg {
    font-size: 20px !important;
}

.ant-menu-item svg {
    color: #53545c;
}

.ant-menu-item.ant-menu-item-selected svg {
    color: #fff;
}

.ant-menu-item .icon svg {
    font-size: 20px !important;
    color: transparent;
    stroke: #53545c;
}

.ant-menu-item.ant-menu-item-selected .icon svg {
    stroke: #fff;
}