.form {
    width: 100%;

    .filter {
        width: 100%;

        .phase {
            width: 750px;
        }
    }
    .checkbox {
        font-size: 15px;
    }
}
