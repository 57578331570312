.icons {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #94b4c6;
    font-weight: bold;
}
.title {
    margin-top: 20px;
}
.date {
    width: 100%;
}
.number {
    width: 100%;
}
