.container {
    padding-top: 16px;

    .alert {
        margin: 4px 0;
        color: #94b4c6;
    }

    .list {
        margin-top: 24px;
        margin-bottom: 20px;
    }
}
