.menu {
    margin-top: 36px;

    .cards {
        padding-bottom: 30px;
    }

    .item {
        height: 100%;
        padding-top: 40px;
        text-align: center;

        .image {
            width: 35%;
        }
        .imageCertificate {
            width: 50%;
        }
    }

    .inactive {
        pointer-events: none;
        opacity: 0.6;
        background-color: #fefefe;
    }
}
