.container {
    .select {
        width: 100%;
    }

    .file {
        border: 1px solid #ccc;
        border-radius: 12px;
        padding: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
        height: 200px;
        overflow-y: scroll;
    }
}

.table {
    width: 100%;
    font-size: 13px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border-collapse: collapse;
    margin: 24px 0;

    tr {
        th {
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            text-align: right;
            font-weight: 400;
        }
        td {
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            text-align: right;

            &.left {
                text-align: left;
            }
        }

        &.subheader {
            background-color: rgba(0, 0, 0, 0.01);
            text-align: right;

            td {
                font-weight: 800;
            }
        }
    }
}
