.form {
    .filter {
        width: 100%;
        div:first-child {
            flex-grow: 1;
        }

        .tax {
            width: 200px;
        }

        .state {
            width: 400px;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        font-size: smaller;
        border: none;
    }
}

.table {
    flex-grow: 1;

    .link {
        color: #ff7e73;
        font-weight: bold;
    }
}
