.form {
    margin-bottom: 24px;

    .filter {
        width: 100%;

        div:first-child {
            flex-grow: 1;
        }

        .tax {
            width: 200px;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        font-size: smaller;
        border: none;
    }
}

list {
    flex-grow: 1;
    .link {
        color: #ff7e73;
        font-weight: bold;
    }
}
.text {
    text-transform: uppercase;
}
.loadMore {
    text-align: center;
    margin-top: 12px;
    height: 32px;
    line-height: '32px';
}
.date {
    align-items: end;
    font-weight: normal;
}
.button {
    color: #94b4c6;
}
