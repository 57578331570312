.container {
    margin-bottom: 24px;

    .files {
        margin-bottom: 24px;
    }

    .heading {
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: bold;
    }

    .inputNumber {
        width: 100%;
    }

    .item {
        padding-left: 0;
    }
    .date {
        width: 100%;
    }
}
