.container {
    .select {
        width: 100%;
    }

    .file {
        border: 1px solid #ccc;
        border-radius: 12px;
        padding: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
        height: 200px;
        overflow-y: scroll;
    }
}
